import React from 'react'
import Layout from '../components/Layout'
import Content from '../components/Models/Content'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Header from '../components/Header'
import Banner from '../components/Banner'

export default function Models({ data, pageContext }) {
  const { t } = useTranslation()
  const models = data.allModels.edges
  return (
    <Layout
      className="with-banner models about"
      locale={pageContext.language}
      template="models"
    >
      <Header black>
        <Banner title={t('page_models_title')} className="about-title" />
      </Header>
      <main>
        <Content models={models} lang={pageContext.language} />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allModels(filter: { showcase: { eq: true } }) {
      edges {
        node {
          id
          order
          updatedAt
          publishedVersion
          apps
          name {
            en
          }
          miniature
          desktop
          mobile
          landscape
          showcase
          createdAt
          currentState
          currentVersion
        }
      }
    }
  }
`
