import React from 'react'
import Link from 'gatsby-link'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Content = ({ models, lang }) => {
  const { t } = useTranslation()
  return (
    <>
      <main class="bg-grey">
        <div class="container">
          <div class="row main-content">
            <div class="col-lg-12 content">
              <div className="projects-and-models models">
                <h2 className="projects-and-models__text h4-style font-bold">
                  {t('page_models_subtitle')}
                </h2>
              </div>
              <div className="all-models">
                <div className="card-template">
                  {models
                    .filter((model) => model.node.showcase === true)
                    .sort((a, b) => a.node.order - b.node.order)
                    .map((model, i) => (
                      <div key={i} className="card-template_item">
                        <Link
                          // to={
                          //   (lang === 'fr' ? '' : lang) +
                          //   '/models/' +
                          //   model.node.name.en
                          // }
                          to={'/models/' + model.node.name.en}
                        >
                          <img
                            src={model.node.miniature}
                            alt={model.node.name.en}
                          />
                          {/* <h3 className="title">{model.node.name.en}</h3> */}
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
              <h4 className="h4-style" style={{ textAlign: 'center' }}>
                {t('page_models_bottom_title')}
              </h4>
              <div
                className=""
                style={{
                  margin: 'auto',
                  textAlign: 'center',
                  marginTop: '70px',
                  marginBottom: '170px',
                }}
              >
                <Link
                  to={'/contact'}
                  className="collaborate__card--btn btn btn-primary"
                >
                  {t('page_models_bottom_button')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Content
